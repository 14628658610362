require("@rails/ujs").start()
require("@rails/activestorage").start()
require("channels")

import "bootstrap"
import "tablesorter"
import "@fortawesome/fontawesome-free/js/all"
import "../stylesheets/application"

global.$ = require('jquery'), require('jquery-blockui'), require('tablesorter'), require('tablesorter/dist/js/extras/jquery.tablesorter.pager.min.js'), require("tablesorter-pagercontrols");

require("jquery-ui/ui/widgets/datepicker")
require("jquery-ui/ui/widgets/autocomplete")

$(document).ready(function(){
    $('[data-toggle="tooltip"]').tooltip();
});

$(document).ready(function(){
    $('.datepicker').datepicker({dateFormat: 'yy/mm/dd'});
});

$.tablesorter.themes.bootstrap = {
    table        : 'table table-striped',
    caption      : 'caption',
    header       : 'bootstrap-header',
    iconSortNone : 'fas fa-sort',
    iconSortAsc  : 'fas fa-sort-up',
    iconSortDesc : 'fas fa-sort-down',
};

document.addEventListener("DOMContentLoaded",function(){
    var scroll = new SmoothScroll('a[href*="#"]',{
      header: '[data-scroll-header]',
      durationMax: 500,
    });
    if (document.location.hash) {
      scroll.animateScroll(document.location.hash);
    }

    var has_polling_elm = document.querySelectorAll('.polling_and_reload').length > 0;
    if(has_polling_elm){
        setTimeout(function(){location.reload();},10000);
    }
});


